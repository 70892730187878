import { gql } from 'apollo-boost';

import { ARTWORK_DETAILS, EVENT_DETAILS } from './fragments';

export const CREATE_ARTWORK = gql`
    mutation CreateArtwork($input: ArtworkInput!) {
        createArtwork(input: $input) {
            ...artworkDetails
        }
    }
    ${ARTWORK_DETAILS}
`;

export const EDIT_ARTWORK = gql`
    mutation EditArtwork($id: ID!, $input: ArtworkEditInput!) {
        editArtwork(id: $id, input: $input)
    }
`;

export const CREATE_EVENT = gql`
    mutation CreateEvent($input: EventInput!) {
        createEvent(input: $input) {
            ...eventDetails
        }
    }
    ${EVENT_DETAILS}
`;

export const EDIT_EVENT = gql`
    mutation EditEvent($id: ID!, $input: EventEditInput!) {
        editEvent(id: $id, input: $input)
    }
`;

export const DELETE_EVENT = gql`
    mutation DeleteEvent($id: ID!) {
        deleteEvent(id: $id)
    }
`;

export const INITIALIZE_BOOKING = gql`
    mutation InitializeBooking($input: InitializeBookingInput!) {
        initializeBooking(input: $input)
    }
`;

export const SUBMIT_BOOKING = gql`
    mutation SubmitBooking($input: BookingInput!) {
        submitBooking(input: $input) {
            id
            price
            customer {
                firstName
                lastName
                phone
                email
            }
            event {
                ...eventDetails
            }
        }
    }
    ${EVENT_DETAILS}
`;