import { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { AuthContext } from './Auth';
import { useInput } from '../hooks/input-hook';
import firebaseApp from './firebase';

const Login = ({ history }) => {
    // Form input
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');

    const handleSubmit = useCallback(
        async event => {
            event.preventDefault();
            try {
                await firebaseApp
                    .auth()
                    .signInWithEmailAndPassword(email, password);
                history.push('/manage');
            } catch (error) {
                alert(error);
            }
        },
        [history, email, password]
    );

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to='/manage'/>
    }

    const isDisabled = () => {
        return !email || !password;
    }

    return (
        <Row style={{height:'500px', textAlign:'center'}}>
            <Col md={4}></Col>
            <Col md={4}>
                <h3 style={{marginBottom:'20px'}}>Log In</h3>
                <Form>
                    <Form.Control style={{marginBottom:'20px'}} id='email' type='email' placeholder='Email' {...bindEmail}/>
                    <Form.Control style={{marginBottom:'20px'}} id='password' type='password' placeholder='Password' {...bindPassword}/>
                </Form>
                <Button
                    size='lg' 
                    block
                    onClick={(e) => handleSubmit(e)}
                    disabled={isDisabled()}
                >ENTER</Button>
            </Col>
        </Row>
    );
};

export default withRouter(Login);