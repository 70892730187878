import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const About = () => {

    return (
        <div style={{textAlign:'center'}}>
            <h1>ABOUT THE OWNER</h1>
            <Row>
                <Col></Col>
                <Col md={8}>
                    <p>
                    Kristen Vincelette, an artist by nature, a student for life, a Graphic Designer and an Illustrator by degree. A resident of Westport,
                    Massachusetts, she received her first artistic award at Westport Elementary School. A door to her future endowment was now opened
                    as she continued practicing her craft through Westport Middle and Westport High. Her college years began at Bristol Community
                    College - Fall River, Massachusetts where she graduated with an Associate in Arts Degree with a concentration in Graphic Design.
                    Then as an art transfer student into the College of Visual Arts at The University of Massachusetts - Dartmouth, she rounded out her
                    education with a Bachelor of Arts Degree in Illustration. Her passion lies in teaching painting and in creating images that are TRUE
                    to LINE and VIBRANT in COLOR.
                    </p>
                </Col>
                <Col></Col>
            </Row>
        </div>
    )
}

export default About;