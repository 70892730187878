import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { AuthProvider } from './auth/Auth';
import PrivateRoute from './auth/PrivateRoute';
import Login from './auth/Login';
import Home from './home/Home';
import Events from './event/Events';
import Footer from './footer/Footer';
import Manage from './manage/Manage';
import Contact from './contact/Contact';
import About from './about/About';

import './App.css';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

const stripePromise = loadStripe("pk_live_Kt5Qn2rVVGC4MlIUni18hmZ5");

const App = () => {
  const comingSoon = <div style={{textAlign:'center'}}>
      <h5>
        Stay tuned, this page is under construction.<br/>
        Sorry for the inconvenience!<br/><br/>Please email creativitywithcolor2017@gmail.com for any questions.
      </h5>
      <Image style={{height:'500px', paddingBottom:'20px'}} src='/wineGlassLogo.png'/>
  </div>

  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <ApolloProvider client={client}>
          <Router>
            <div className='page-container'>
              <div className='content-wrap'>
                <Navbar className='navbar-custom' expand='md'>
                  <Navbar.Brand as={Link} to='/'>
                    <Image fluid className='navLogo' src='/wordLogo.png'/>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls='basic-navbar-nav' />
                  <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='mr-auto'></Nav>
                    <Nav>
                      <Nav.Link as={Link} to='/'>
                        HOME
                      </Nav.Link>
                      <Nav.Link as={Link} to='/events'>
                        EVENTS
                      </Nav.Link>
                      <Nav.Link as={Link} to='/about'>
                        ABOUT
                      </Nav.Link>
                      {/* 
                      <Nav.Link as={Link} to='/faq'>
                        FAQ
                      </Nav.Link> */}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>

                <Switch>
                  <Route exact path='/'>
                    <Home/>
                  </Route>
                  <Route path='/events'>
                    <Container><Events/></Container>
                  </Route>
                  <Route path='/about'>
                    <Container><About/></Container>
                  </Route>
                  <Route path='/contact'>
                    <Container><Contact/></Container>
                  </Route>
                  <Route path='/faq'>
                    <Container><h1>FAQ</h1>{comingSoon}</Container>
                  </Route>
                  <PrivateRoute exact path='/manage' component={Manage}/>
                  <Route path='/login'>
                    <Container><Login/></Container>
                  </Route>
                </Switch>
              </div>
              <Footer/>
            </div>
          </Router>
        </ApolloProvider>
      </Elements>
    </AuthProvider>
  )
}

export default App;
