import { gql } from 'apollo-boost';

import { ARTWORK_DETAILS, EVENT_DETAILS, EVENT_DETAILS_MANAGE } from './fragments';

export const GET_ARTWORKS = gql`
    query($name: String) {
        artworks(name: $name) {
            ...artworkDetails
        }
    }
    ${ARTWORK_DETAILS}
`;

export const GET_EVENTS = gql`
    query($start: Date, $end: Date, $limit: Int) {
        events(start: $start, end: $end, limit: $limit) {
            ...eventDetails
        }
    }
    ${EVENT_DETAILS}
`;

export const GET_EVENTS_MANAGE = gql`
    query($start: Date, $end: Date, $limit: Int) {
        events(start: $start, end: $end, limit: $limit) {
            ...eventDetailsManage
        }
    }
    ${EVENT_DETAILS_MANAGE}
`;

export const GET_EVENT = gql`
    query($id: ID!) {
        event(id: $id) {
            ...eventDetails
        }
    }
    ${EVENT_DETAILS}
`;