import { gql } from 'apollo-boost';

export const ARTWORK_DETAILS = gql`
    fragment artworkDetails on Artwork {
        id
        description
        dimensions {
            height
            width
        }
        image
        name
        type
    }
`;

export const EVENT_DETAILS = gql`
    fragment eventDetails on Event {
        id
        artwork {
            ...artworkDetails
        }
        price
        spots {
            remaining
            reserved
        }
        dayOfWeek
        month
        dayOfMonth
        startTime
        endTime
        start
        end
    }
    ${ARTWORK_DETAILS}
`;

export const EVENT_DETAILS_MANAGE = gql`
    fragment eventDetailsManage on Event {
        id
        artwork {
            ...artworkDetails
        }
        bookings {
            customer {
                firstName
                lastName
                email
            }
            price
            groupName
            painterNames
        }
        price
        spots {
            remaining
            reserved
        }
        dayOfWeek
        month
        dayOfMonth
        startTime
        endTime
        start
        end
    }
    ${ARTWORK_DETAILS}
`;