import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './footer.css';

const Footer = () => {
    return (
        <div className='Footer padded'>
            <Container>
                <Row>
                    <Col>
                        <p>BOOK</p>
                        <Link to={'/events'}>Events</Link>
                    </Col>
                    <Col>
                        <p>DISCOVER</p>
                        <Link to={'/about'}>About</Link>
                    </Col>
                    <Col>
                        <p>INFO</p>
                        <Link to={'/contact'}>Contact Us</Link><br/>
                        <Link to={'/faq'}>FAQ</Link>
                    </Col>
                    <Col>
                        <p>MORE</p>
                        <a href='https://www.facebook.com/creativitywithcolor'>Facebook</a><br/>
                        <a href='mailto:creativitywithcolor2017@gmail.com'>Email</a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;