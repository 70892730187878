import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import firebaseApp from '../auth/firebase';
import { useInput } from '../hooks/input-hook';
import { GET_ARTWORKS } from '../graphql/queries';
import { CREATE_ARTWORK, CREATE_EVENT } from '../graphql/mutations';

import { getImageUrl } from '../common/utils'

import './manage.css';

const twoDimensionalArray = (items, size) => {
    var result = [];
    for (var i =0; i < items.length; i+=size) {
        result.push(items.slice(i, i+size));
    }
    return result;
}

const artworkTypes = ['PAINTING', 'BAG', 'ORNAMENT', 'WINE_GLASS', 'OTHER'];
var storageRef = firebaseApp.storage().ref();

const AddEvent = () => {
    const [succeeded, setSucceeded] = useState(false);
    const [isNewArtwork, setIsNewArtwork] = useState(true);
    const { value:artworkQuery, bind:bindArtworkQuery, reset:resetArtworkQuery } = useInput('');
    const [selectedArtwork, setSelectedArtwork] = useState(undefined);
    const [createdName, setCreatedName] = useState('');
    const { value:name, bind:bindName, reset:resetName } = useInput('');
    const { value:description, bind:bindDescription, reset:resetDescription } = useInput('');
    const { value:width, bind:bindWidth, reset:resetWidth } = useInput(undefined);
    const { value:height, bind:bindHeight, reset:resetHeight } = useInput(undefined);
    const [file, setFile] = useState(null);
    const [type, setType] = useState('PAINTING');
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const { value:numberOfPainters, bind:bindNumberOfPainters, reset:resetNumberOfPainters } = useInput(10);
    const [price, setPrice] = useState(undefined);

    // Queries
    const [getArtworks, { data: artworkData, loading, error }] = useLazyQuery(GET_ARTWORKS);

    // Mutations
    const [createArtwork] = useMutation(CREATE_ARTWORK, {
        onCompleted: (data) => {
            const artwork = data.createArtwork;

            createEvent({variables: {
                input: {
                    artwork: artwork.id,
                    price: parseInt(price) * 100,
                    spots: parseInt(numberOfPainters),
                    start: startDate,
                    end: endDate
                }
            }});
        }
    });
    const [createEvent] = useMutation(CREATE_EVENT, {
        onCompleted: (data) => {
            setSucceeded(true);
        }
    });

    const splitArtworks = !loading && !error && artworkData ? twoDimensionalArray(artworkData.artworks, 6) : [];

    const newArtworkSelected = () => {
        setIsNewArtwork(true);
        setSelectedArtwork(undefined);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isNewArtwork) {
            var imageRef = storageRef.child(`public/${file.name}`);
            var uploaded = await imageRef.put(file);
            if (!uploaded) {
                alert('Failed to upload image');
                return;
            }

            setCreatedName(name);
            var dimensions = null;
            if (height && width) {
                dimensions = {
                    height: parseInt(height),
                    width: parseInt(width)
                };
            }
            createArtwork({variables: {
                input: {
                    description: description,
                    dimensions: dimensions,
                    image: file.name,
                    name: name,
                    type: type
                }
            }});
        } else {
            createEvent({variables: {
                input: {
                    artwork: selectedArtwork.id,
                    price: parseInt(price) * 100,
                    spots: parseInt(numberOfPainters),
                    start: startDate,
                    end: endDate
                }
            }});
            setCreatedName(selectedArtwork.name);
        }
    }

    return (
        <div className='AddEvent'>
            <Container>
                {
                    succeeded && (
                        <h4>Created event for {createdName}! Enter new values to create another</h4>
                    )
                }
                <Form>
                    <Card>
                        <Card.Header>
                            Artwork Details<br/>
                            <ButtonGroup>
                                <Button active={isNewArtwork} onClick={() => newArtworkSelected()}>New</Button>
                                <Button active={!isNewArtwork} onClick={() => setIsNewArtwork(false)}>Old</Button>
                            </ButtonGroup>
                        </Card.Header>
                        <Card.Body>
                            {   !isNewArtwork &&
                                <>
                                    <Row>
                                        <Col md={10}>
                                            <Form.Control id='artworkQuery' type='text' placeholder='Artwork Name' {...bindArtworkQuery}/>
                                        </Col>
                                        <Col md={2}>
                                            <Button
                                                size='sm' 
                                                block
                                                onClick={() => getArtworks({
                                                    variables: {
                                                        name: artworkQuery
                                                    }
                                                })}
                                            >Search</Button>
                                        </Col>
                                    </Row>
                                    {   loading &&
                                        <h3>Loading artworks...</h3>
                                    }
                                    { selectedArtwork !== undefined &&
                                        <h2>{selectedArtwork.name}</h2>
                                    }
                                    { artworkData && artworkData.artworks &&
                                        splitArtworks.map((row, i) => (
                                            <Row key={i}>
                                                {row.map((artwork, j) => (
                                                    <Col key={j} md={2}>
                                                        <Image 
                                                            onClick={() => setSelectedArtwork(artwork)}
                                                            className={
                                                                selectedArtwork !== undefined && selectedArtwork.id == artwork.id ?
                                                                'selectedArtwork' :
                                                                ''
                                                            }
                                                            src={getImageUrl(artwork.image)} fluid style={{borderRadius: '0.3em'}}
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))
                                    }
                                </>
                            }
                            {
                                isNewArtwork &&
                                <>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control id='name' type='text' placeholder='Name' {...bindName}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control id='description' type='textArea' placeholder='Description' {...bindDescription}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.File onChange={(e) => setFile(e.target.files[0])} label="Picture" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control as='select' onChange={e => setType(e.target.value)}>
                                            {artworkTypes.map((type, index) => (
                                                <option key={index} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Dimensions (inches)</Form.Label>
                                        <Form.Row>
                                            <Col>
                                                <Form.Control id='width' type='number' placeholder="Width (if applicable)" {...bindWidth} />
                                            </Col>
                                            <Col>
                                                <Form.Control id='height' type='number' placeholder="Height (if applicable)" {...bindHeight} />
                                            </Col>
                                        </Form.Row>
                                    </Form.Group>
                                </>
                            }
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>Event Details</Card.Header>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>
                                    Event Day and Time
                                </Form.Label>
                                <Form.Row>
                                    <Col>
                                        <DatePicker 
                                            style={{width:'100%'}}
                                            showTimeInput
                                            selected={startDate}
                                            onChange={date => setStartDate(date.getTime())}
                                            placeholderText='Start'
                                            timeInputLabel="Time:"
                                            dateFormat='MMMM do, yyyy hh:mma'
                                            minDate={new Date()}
                                            shouldCloseOnSelect={false}
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker 
                                            showTimeInput
                                            selected={endDate}
                                            onChange={date => setEndDate(date.getTime())}
                                            placeholderText='End'
                                            timeInputLabel="Time:"
                                            dateFormat='MMMM do, yyyy hh:mm a'
                                            minDate={startDate ? startDate : new Date()}
                                            shouldCloseOnSelect={false}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Number of Painters</Form.Label>
                                <Form.Control id='numberOfPainters' type='number' placeholder='Number of painters' {...bindNumberOfPainters}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Price</Form.Label><br/>
                                <CurrencyInput
                                    id='price'
                                    name='price'
                                    placeholder='$100.00'
                                    prefix='$'
                                    decimalsLimit={2}
                                    onValueChange={(value) => setPrice(value)}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Form>
                <Button
                    size='lg' 
                    block
                    onClick={(e) => handleSubmit(e)}
                >CREATE</Button>
            </Container>

        </div>
    )
};

export default AddEvent;