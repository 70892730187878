import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import EventCard from './EventCard';

import { GET_EVENTS } from '../graphql/queries';

const defaultColumns = 6;

const EventsRow = ({columns = defaultColumns}) => {
    const [startTime, setStartTime] = useState(0);

    useEffect(() => {
        setStartTime(Date.now());
    }, []);

    const { data, loading, error } = useQuery(GET_EVENTS, {
        variables: {
            start: startTime,
            limit: defaultColumns
        }
    });
    if (loading) {
        return <p>Loading...</p>
    }
    if (error) {
        return <div></div>
    }

    return (
        <div className='EventsRow padded'>
            <Row>
                <Col>
                    <h3>UPCOMING EVENTS</h3>
                </Col>
                <Col style={{textAlign:'right'}}>
                    <Link to={`/events`}>
                        VIEW MORE ►
                    </Link>
                </Col>
            </Row>
            <Row >
                {data.events.map((event, j) => (
                    <Col key={j} md={12 / columns}>
                        <Link to={`/events/${event.id}`}>
                            <EventCard key={event.id} compact={true} event={event}/>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default EventsRow;