import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import firebaseApp from '../auth/firebase';
import { EDIT_EVENT, EDIT_ARTWORK, DELETE_EVENT } from '../graphql/mutations';

import { getImageUrl } from '../common/utils'

import './manage.css';

const twoDimensionalArray = (items, size) => {
    var result = [];
    for (var i =0; i < items.length; i+=size) {
        result.push(items.slice(i, i+size));
    }
    return result;
}

const artworkTypes = ['PAINTING', 'BAG', 'ORNAMENT', 'WINE_GLASS', 'OTHER'];
var storageRef = firebaseApp.storage().ref();

const EditEvent = ({event}) => {
    const [succeeded, setSucceeded] = useState(undefined);
    const [artworkSucceeded, setArtworkSucceeded] = useState(undefined);
    const [artworkUpdated, setArtworkUpdated] = useState(undefined);
    const [startDate, setStartDate] = useState(event.start);
    const [endDate, setEndDate] = useState(event.end);
    const [spotsRemaining, setSpotsRemaining] = useState(event.spots.remaining);
    const [spotsReserved, setSpotsReserved] = useState(event.spots.reserved);
    const [artworkName, setArtworkName] = useState(event.artwork.name);
    const [price, setPrice] = useState(event.price / 100);

    // Mutations
    const [editEvent] = useMutation(EDIT_EVENT, {
        onCompleted: (data) => {
            setSucceeded(true);
        }
    });
    const [editArtwork] = useMutation(EDIT_ARTWORK, {
        onCompleted: (data) => {
            setArtworkUpdated(false);
        }
    })
    const [deleteEvent] = useMutation(DELETE_EVENT, {
        onCompleted: (data) => {
            window.location.reload(false);
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSucceeded(false);

        editEvent({variables: {
            id: event.id,
            input: {
                start: startDate,
                end: endDate,
                price: price * 100,
                spots: {
                    remaining: spotsRemaining,
                    reserved: spotsReserved
                }
            }
        }})
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        setSucceeded(false);

        if (event.bookings && event.bookings.length > 0) {
            return
        }

        deleteEvent({variables: {
            id: event.id
        }})
    }

    const updateArtwork = (value) => {
        setArtworkName(value)
        setArtworkUpdated(true);
    }

    const submitArtworkName = async (e) => {
        e.preventDefault();
        
        editArtwork({variables: {
            id: event.artwork.id,
            input: {
                name: artworkName
            }
        }})
    }

    return (
        <div className='EditEvent'>
            <Container>
                <Form>
                    <Card>
                        <Card.Header>
                            <Form.Control
                                id='artworkName' type='text' placeholder='Artwork Name'
                                value={artworkName}
                                onChange={(e) => updateArtwork(e.target.value)}
                            >
                            </Form.Control>
                            {
                                artworkUpdated ?
                                <Button
                                    size='lg' 
                                    block
                                    onClick={(e) => submitArtworkName(e)}
                                >UPDATE NAME</Button> :
                                <span></span>
                            }
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Image style={{width:'100%'}} src={getImageUrl(event.artwork.image)}/>
                                </Col>
                                <Col md={8}>
                                    {
                                        event.bookings && event.bookings.length > 0 &&
                                        <div>
                                                
                                                <Row>
                                                    <h2>Bookings</h2>
                                                </Row>
                                                <hr/>
                                                {event.bookings.map((booking, i) => (
                                                    <>
                                                        <Row key={'name'+i}>
                                                            <h4>{booking.customer.firstName} {booking.customer.lastName}</h4>
                                                        </Row>
                                                        <Row key={'email'+i} style={{paddingBottom: '20px'}}>
                                                            <Col>{booking.customer.email}</Col>
                                                            <Col>Party of {booking.price / event.price}</Col>
                                                        </Row>
                                                        <Row key={'group'+i} style={{paddingBottom: '20px'}}>
                                                            <Col>Painters: {booking.painterNames}</Col>
                                                            {
                                                                booking.groupName ? 
                                                                <Col>Group: {booking.groupName}</Col> : <></>
                                                            }
                                                        </Row>
                                                    </>
                                                ))}
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>Event Details</Card.Header>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>
                                    Event Day and Time
                                </Form.Label>
                                <Form.Row>
                                    <Col>
                                        <DatePicker 
                                            style={{width:'100%'}}
                                            showTimeInput
                                            selected={startDate}
                                            onChange={date => setStartDate(date.getTime())}
                                            placeholderText='Start'
                                            timeInputLabel="Time:"
                                            dateFormat='MMMM do, yyyy hh:mma'
                                            minDate={new Date()}
                                            shouldCloseOnSelect={false}
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker 
                                            showTimeInput
                                            selected={endDate}
                                            onChange={date => setEndDate(date.getTime())}
                                            placeholderText='End'
                                            timeInputLabel="Time:"
                                            dateFormat='MMMM do, yyyy hh:mm a'
                                            minDate={startDate ? startDate : new Date()}
                                            shouldCloseOnSelect={false}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>Spots Remaining</Form.Label>
                                        <Form.Control 
                                            id='numberOfPainters' type='number' placeholder='Number of painters'
                                            value={spotsRemaining}
                                            onChange={(e) => setSpotsRemaining(parseInt(e.target.value))}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Spots Reserved</Form.Label>
                                        <Form.Control 
                                            id='numberOfPainters' type='number' placeholder='Number of painters'
                                            value={spotsReserved}
                                            onChange={(e) => setSpotsReserved(parseInt(e.target.value))}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Price</Form.Label><br/>
                                <CurrencyInput
                                    id='price'
                                    name='price'
                                    placeholder='$100.00'
                                    prefix='$'
                                    decimalsLimit={2}
                                    value={price}
                                    onValueChange={(value) => setPrice(value)}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Form>
                {
                    succeeded && (
                        <h4>Updated!</h4>
                    )
                }
                <Row>
                    <Col>
                        <Button
                            size='lg' 
                            block
                            onClick={(e) => handleSubmit(e)}
                        >UPDATE</Button>
                    </Col>
                    <Col>
                        <Button
                            size='lg' 
                            block
                            className='deleteButton'
                            disabled={event.bookings && event.bookings.length > 0}
                            onClick={(e) => handleDelete(e)}
                        >DELETE</Button>
                    </Col>
                </Row>
            </Container>

        </div>
    )
};

export default EditEvent;