import { useQuery } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import firebaseApp from '../auth/firebase';

import AddEvent from './AddEvent';
import EditEvent from './EditEvent';
import EventCard from '../event/EventCard';
import { GET_EVENTS_MANAGE } from '../graphql/queries';

import { twoDimensionalArray } from '../common/utils';

import './manage.css';

const columns = 6;

const Manage = () => {

    const [mode, setMode] = useState('manage');
    const [isUpcoming, setIsUpcoming] = useState(true);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(undefined);
    const [event, setEvent] = useState(undefined);

    useEffect(() => {
        setStartTime(Date.now());
    }, []);

    const { data, loading, error } = useQuery(GET_EVENTS_MANAGE, {
        variables: {
            start: startTime,
            end: endTime
        }
    });

    const splitData = !loading && !error ? twoDimensionalArray(data.events, columns) : [];

    const pastClicked = () => {
        setIsUpcoming(false);
        setStartTime(undefined);
        setEndTime(Date.now());
    }
    const upcomingClicked = () => {
        setIsUpcoming(true);
        setStartTime(Date.now());
        setEndTime(undefined);
    }
    const eventClicked = (event) => {
        setEvent(event);
        setMode('edit');
    }
    return (
        <div className='Manage'>
            <Container>
                <Row>
                    <Col>
                        <Button
                            onClick={() => firebaseApp.auth().signOut()}
                        >Sign Out</Button>
                    </Col>
                    <Col style={{textAlign:'center'}} md={8}>
                        <h2>{ mode == 'manage' ? 'Manage' : mode == 'edit' ? 'Edit' : 'Add'}</h2>
                    </Col>
                    <Col style={{textAlign:'right'}}>
                        { mode == 'manage' &&
                            <Button onClick={() => setMode('add')}>
                                Add Event
                            </Button>
                        }
                        { mode != 'manage' && 
                            <Button onClick={() => setMode('manage')}>
                                Back
                            </Button>
                        }
                    </Col>
                </Row>
                { mode == 'manage' &&
                    <>
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <ButtonGroup>
                                <Button active={!isUpcoming} onClick={() => pastClicked()}>
                                    Past Events
                                </Button>
                                <Button active={isUpcoming} onClick={() => upcomingClicked()}>
                                    Upcoming Events
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    { loading &&
                        <h5>Loading events...</h5>
                    }
                    {splitData.map((row, i) => (
                        <Row key={i}>
                            {row.map((event, j) => (
                                <Col key={j} md={12 / columns} onClick={() => eventClicked(event)} >
                                    <EventCard 
                                        className='EventCard' key={event.id} compact={true} event={event}   
                                    />
                                </Col>
                            ))}
                        </Row>
                    ))}
                    </>
                }
                {
                    mode == 'add' &&
                    <AddEvent/>
                }
                {
                    mode == 'edit' &&
                    <EditEvent event={event}/>
                }
            </Container>

        </div>
    )
};

export default Manage;