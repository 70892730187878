import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { getImageUrl, formatPrice } from '../common/utils';

const EventCard = ({event, compact=false}) => {
    return (
    <div className='EventCard'>
        <Card>
            <Card.Img variant='top' src={getImageUrl(event.artwork.image)} />
            {compact &&
                <Card.Body>
                    <Card.Text>
                        {event.dayOfWeek}
                        <br/>
                        {event.month} {event.dayOfMonth}
                        <br/>
                        {event.startTime}
                    </Card.Text>
                </Card.Body>
            }
            {!compact && 
                <Card.Body>
                    <Row>
                        <Col>
                            <Card.Title>
                                {event.dayOfWeek}
                                <br/>
                                {event.month} {event.dayOfMonth}
                                <br/>
                                {event.startTime} to {event.endTime}
                            </Card.Title>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Card.Text>
                                {event.artwork.name}
                                <br/>
                                {formatPrice(event.price)} per painter
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            }
        </Card>
    </div>
    )
}

export default EventCard;