import { useMutation } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
    CardElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";

import { getImageUrl, formatPrice } from '../common/utils';
import { INITIALIZE_BOOKING, SUBMIT_BOOKING } from '../graphql/mutations';
import { useInput } from '../hooks/input-hook';

import './booking.css';

const Booking = ({event, quantity, price}) => {
    // Stripe
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const handleCardChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            },
            receipt_email: email,
        });
        if (payload.error) {
            setError(`Payment failed: ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            submitBooking({variables: {
                input: {
                    event: event.id,
                    customer: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                    },
                    numberOfPainters: parseInt(quantity),
                    price: price,
                    groupName: groupName,
                    painterNames: painterNames
                }
            }});
        }
    }
    
    // Mutations
    const [initializeBooking] = useMutation(INITIALIZE_BOOKING, {
        onCompleted: (data) => {
            setClientSecret(data.initializeBooking);
        }
    });
    const [submitBooking] = useMutation(SUBMIT_BOOKING, {
        onCompleted: (data) => {
            setBooking(data.submitBooking);
            setProcessing(false);
            setSucceeded(true);
        }
    });

    // On component mount
    useEffect(() => {
        initializeBooking({variables: {
            input: {
                event: event.id,
                numberOfPainters: parseInt(quantity),
                price: price
            }
        }});
    }, []);

    // Form input
    const { value:firstName, bind:bindFirstName, reset:resetFirstName } = useInput('');
    const { value:lastName, bind:bindLastName, reset:resetLastName } = useInput('');
    const { value:phone, bind:bindPhone, reset:resetPhone } = useInput('');
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:groupName, bind:bindGroupName, reset:resetGroupName } = useInput('');
    const { value:painterNames, bind:bindPainterNames, reset:resetPainterNames } = useInput('');
    const [booking, setBooking] = useState(null);

    const date = `${event.dayOfWeek}, ${event.month} ${event.dayOfMonth}`;
    var painterNamesInput = () => {
        let items = [];
        for (let i = 1; i <= quantity; i++) {
            items.push(
                <Row key={i} noGutters={true} style={{marginBottom: '10px'}}>
                    <Col>
                        <Form.Control 
                            id={`painter-name-${i}`} 
                            type='text' 
                            placeholder={`Painter ${i}`}
                        />
                    </Col>
                </Row>
            );
        }
        return items;
    }
    const missingInput = () => {
        return !firstName || !lastName || !email || !phone || !painterNames;
    }
    const isDisabled = () => {
        return missingInput() || disabled || processing || succeeded;
    }
    return (
        <div className='Booking'>
            <h1 className='title'>{succeeded ? 'BOOKING CONFIRMED' : 'CHECKOUT'}</h1>
            <Row>
                <Col md={8}>
                    {
                        !succeeded && (
                        <Form>
                            <Card>
                                <Card.Header>Customer Information</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form.Control id='firstName' type='text' placeholder='First Name' {...bindFirstName}/>
                                        </Col>
                                        <Col>
                                            <Form.Control id='lastName' type='text' placeholder='Last Name' {...bindLastName}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control id='email' type='text' placeholder='Email' {...bindEmail}/>
                                        </Col>
                                        <Col>
                                            <Form.Control id='phone' type='text' placeholder='Phone Number' {...bindPhone}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>Party Information</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <h6>Painters</h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p className='helpText'>Please enter the names of all painters below.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control id='painters' type='text' placeholder='Painter Names' {...bindPainterNames} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6>Group</h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p className='helpText'>Sitting with a group? If yes, please enter the group name below so you will be seated together.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control id='groupName' type='text' placeholder='Group Name' {...bindGroupName} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>Payment</Card.Header>
                                <Card.Body>
                                    <CardElement id="card-element" onChange={handleCardChange}/>
                                    {error && (
                                        <div className="card-error" role="alert">
                                            {error}
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Form>)
                    }
                    {
                        succeeded && (
                            <Card>
                                <Card.Header>Thank you for booking!</Card.Header>
                                <Card.Body>
                                    We can't wait to have you for a fun night out!<hr/>
                                    Feel free to arrive up to 30 minutes before the start of your event.
                                </Card.Body>
                            </Card>
                        )
                    }
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img variant='top' src={getImageUrl(event.artwork.image)}/>
                        <Card.Body>
                            <h3>{date}</h3>
                            <h4>{event.startTime} to {event.endTime}</h4>
                            <p>{event.artwork.name}</p>
                            <hr/>
                            <div className='pricing'>
                                <p>Number of painters: {quantity}
                                <br/>Price per painter: {formatPrice(event.price)}
                                </p>
                                <h3>Total: {formatPrice(price)}</h3>
                            </div>
                        </Card.Body>
                        {
                            !succeeded && (
                                <Button
                                    size='lg' 
                                    block
                                    onClick={(e) => handleSubmit(e)}
                                    disabled={isDisabled()}
                                >BOOK</Button>
                            )
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Booking;