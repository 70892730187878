import { useQuery } from '@apollo/react-hooks';
import { useParams } from "react-router-dom";
import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import { getImageUrl, formatPrice } from '../common/utils'
import { GET_EVENT } from '../graphql/queries';

import Booking from '../booking/Booking';

import './event.css';

function withinTimeframe(start, numOfHours) {
    if (start == undefined) {
        return false;
    }
    const startDate = new Date(start);
    const value = startDate.setHours(startDate.getHours() - numOfHours).valueOf() <= new Date().valueOf();

    return value;
}

const EventDetail = () => {
    const { id } = useParams();
    const [inBookingMode, setInBookingMode] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const { data, loading, error } = useQuery(GET_EVENT, {
        variables: {
            id: id
        }
    });
    if (loading) {
        return <p>Loading...</p>
    }
    if (error) {
        console.log(error);
        return <p>Error :(</p>
    }
    const event = data.event;
    const artwork = event.artwork;
    const date = `${event.dayOfWeek}, ${event.month} ${event.dayOfMonth}`;
    const isSoldOut = event.spots.remaining <= 0;
    const isWithinTimeframe = withinTimeframe(event.start, 2);

    var totalPrice = () => {
        return quantity * event.price;
    }
    var numberOfPaintersSelector = () => {
        let items = [];
        items.push(<option key={1} value={1}>1 painter</option>)
        for (let i = 2; i <= event.spots.remaining; i++) {
            items.push(<option key={i} value={i}>{i} painters</option>);
        }
        return items;
    }

    return (
        <div className='EventDetail'>
            { !inBookingMode &&
            <>
                <Row>
                    <Col md={6}>
                        <Image src={getImageUrl(artwork.image)} fluid style={{borderRadius: '0.3em'}}/>
                    </Col>
                    <Col md={6}>
                        <Card style={{height: '100%'}}>
                                <Card.Header>
                                    <h1>{artwork.name}</h1>
                                </Card.Header>
                                <Card.Body>
                                    {date}
                                    <br/>
                                    {event.startTime} to {event.endTime}
                                    <hr/>
                                    {formatPrice(event.price)} per painter
                                    <br/>
                                    {event.spots.remaining} spots remaining
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <Form.Control as='select' 
                                                onChange={e => setQuantity(e.target.value)} 
                                                disabled={isSoldOut || isWithinTimeframe}>
                                                    {numberOfPaintersSelector()}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <h3 style={{textAlign:'right'}}>{formatPrice(totalPrice())}</h3>
                                        </Col>
                                    </Row>
                                    {
                                        isSoldOut ?
                                        <h4 style={{'color': 'red'}}>SOLD OUT</h4> : <span></span>
                                    }
                                    {
                                        isWithinTimeframe ?
                                        <h6 style={{'color': 'red'}}>Sorry, you must book at least two hours in advance.<br/>Please call 774-319-5663 for last minute booking requests.</h6> : <span></span>
                                    }
                                    <Button
                                        style={{marginTop:'10px'}}
                                        size='lg' 
                                        block
                                        disabled={isSoldOut || isWithinTimeframe}
                                        onClick={() => setInBookingMode(true)}
                                    >PROCEED TO CHECKOUT</Button>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                        ABOUT THIS CLASS<br/>
                        This is a BYOB studio, meaning you are welcome to bring food and drinks, including beer and wine. However, please note that 
                        there is a Massachusetts law that prohibits open alcohol bottles from leaving the studio.<br/><br/>
                        Arrival Time: You may arrive up to a half an hour prior to the start of the class.<br/><br/>
                        Cancellation policy: This event is non-refundable. Please reach out at least 24 hours before the event's start time if you would like to reschedule.
                        </p>
                    </Col>
                </Row>
            </>
            }
            { inBookingMode && 
            <>
                <Row>
                    <Col>
                        <Button onClick={() => setInBookingMode(false)}>◄ Back to Details</Button>
                        <Booking event={event} quantity={quantity} price={totalPrice()}/>
                    </Col>
                </Row>
            </>
            }
        </div>
    )

}

export default EventDetail;