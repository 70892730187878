import './contact.css';

const Contact = () => {
    return (
        <div className='Contact'>
            <h2>Contact Us</h2><br/>
            <div className='content'>
                <h4>
                    <span className='key'>Phone: </span>
                    774-319-5663
                </h4>
                <h4>
                    <span className='key'>Email: </span>
                    <a href="mailto:creativitywithcolor2017@gmail.com" class="mailto">creativitywithcolor2017@gmail.com</a></h4>
                <h4>
                    <span className='key'>Address: </span>
                    <a href="https://goo.gl/maps/544sKSJDietSef5V9">151 State Rd, Westport, MA 02790</a>
                </h4>
            </div>
        </div>
    )
};

export default Contact;