export const getImageUrl = (image) => {
    return `https://firebasestorage.googleapis.com/v0/b/creativity-with-color.appspot.com/o/public%2F${image}?alt=media`;
}

export const formatPrice = (price) => {
    return `$${(price / 100).toFixed(2)}`;
}

export const twoDimensionalArray = (items, size) => {
    var result = [];
    for (var i =0; i < items.length; i+=size) {
        result.push(items.slice(i, i+size));
    }
    return result;
}