import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Jumbotron from 'react-bootstrap/Jumbotron';

import EventsRow from '../event/EventsRow';

import './home.css';

const Home = () => {
    return (
        <div>
            <Jumbotron fluid style={{backgroundImage: 'url("/jumbo.jpg")'}}>
                <Image className='logo' src='/logo.png'/>
            </Jumbotron>
            <Container className='upcomingEvents'>
                <EventsRow columns={6} compact={true}/>
            </Container>
            <div className='description padded'>
                <Container>
                    <h2>Creativity is the ability to use your Imagination</h2>
                    <p>
                    At Creativity With Color you can explore, discover, experiment, 
                    and find your inner artistic self.<br/>You will be amazed at what a 
                    dab of paint and color can add to your creative experience.
                    </p>
                </Container>
            </div>
        </div>
    )
}

export default Home;