import { useQuery } from '@apollo/react-hooks';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import EventCard from './EventCard';
import EventDetail from './EventDetail';

import { GET_EVENTS } from '../graphql/queries';

import 'react-datepicker/dist/react-datepicker.css';
import './event.css';

const twoDimensionalArray = (items, size) => {
    var result = [];
    for (var i =0; i < items.length; i+=size) {
        result.push(items.slice(i, i+size));
    }
    return result;
}

const Events = ({columns = 4, compact = false}) => {
    const history = useHistory();
    var { url } = useRouteMatch();
    const [startTime, setStartTime] = useState(0);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    useEffect(() => {
        setStartTime(Date.now());
    }, []);

    const { data, loading, error } = useQuery(GET_EVENTS, {
        variables: {
            start: startDate ? startDate : startTime,
            end: endDate
        }
    });

    const filters = startDate || endDate;
    const noEvents = filters ? 'No events found, please try clearing filters.' : 'No events scheduled at this time. Please check back soon!';
    const splitData = !loading && !error ? twoDimensionalArray(data.events, columns) : [];

    return (
        <div className='Events'>
            <Route path={`${url}/:id`}>
                <EventDetail/>
            </Route>
            <Route exact path={url}>
                <Row className='padded'>
                    <Col md='auto'>
                        <DatePicker 
                            selected={startDate}
                            onChange={date => setStartDate(date.getTime())}
                            placeholderText='Start date'
                            dateFormat='MMMM do, yyyy'
                            minDate={new Date()}
                        />
                    </Col>
                    <Col md='auto'>
                        <DatePicker 
                            selected={endDate}
                            onChange={date => setEndDate(date.getTime())}
                            placeholderText='End date'
                            dateFormat='MMMM do, yyyy'
                            minDate={startDate ? startDate : new Date()}
                        />
                    </Col>
                    { filters && 
                        <Col md='auto'>
                            <Button size='sm' onClick={() => {
                                setStartDate(undefined);
                                setEndDate(undefined);
                            }}>
                                ✕ Clear filters
                            </Button>
                        </Col>
                    }
                </Row>
                {splitData.map((row, i) => (
                    <Row key={i}>
                        {row.map((event, j) => (
                            <Col key={j} md={12 / columns} onClick={() => history.push(`${url}/${event.id}`)}>
                                <EventCard className='EventCard' key={event.id} compact={compact} event={event}/>
                            </Col>
                        ))}
                    </Row>
                ))}
                {loading && 
                    <h4 className='loading'>Loading...</h4>
                }
                {!loading && !error && splitData.length == 0 &&
                    <h4 className='noEvents'>
                        {noEvents}
                    </h4>
                }
            </Route>
        </div>
    )
}

export default Events;